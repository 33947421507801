<template>
  <v-app id="home">
    
    <NavBar/>
    <v-container fluid style="background-color: none; height:100%;">
    <div style="position: absolute; background-color: #111; height: 150px; width: 100%;"></div>
    <div class="parallaxWrap">
      <div class="head" id="foreground">
        <h2 class="layer" style="z-index: -5;">---</h2>
        <v-row>
          <v-col class="greeting" cols="5">
            <div style="position: relative;" class="mt-16">
            <h1 class="text-white">Hello!</h1>
            <h1 class="text-white">I am Zewen</h1>
            <span class="text-grey"> SE New Grad</span>
            </div>
          </v-col>
          
          <v-col cols="2" @click="scroll('project')" style="cursor: pointer;">
            <div style="position: absolute; z-index:999;bottom:0; margin-left:auto; margin-right:auto; left: 0;right: 0; text-align: center;" class="mt-16">
              <v-icon> fas fa-angle-double-down</v-icon>
            </div>
          </v-col>
          <v-col cols="5">
            <div style="position: relative; z-index:999;" class="mt-16" >
              <svg id="bigLogo" display="relative" height="90" viewBox="0 0 168 174" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.5 21L14.5 1H167L90.5 133L80 115L134 21H25.5Z" stroke="white"/>
                  <path d="M142.736 153.3L153.731 173.303L1.23145 173.268L77.7617 41.2851L88.2576 59.2875L34.236 153.275L142.736 153.3Z" stroke="white" />
              </svg>
            </div>
          </v-col>
        </v-row>
      </div>
 
      <div class="head head2" id="background"></div>
    </div>


    <v-col cols="12" class="mt-16" id="about">    
      <div>
        <v-row>
          <v-col cols="12" sm="6">
            <div id="avater-background">
              <v-avatar style="margin-top: 30px; margin-left: 50%; transform: translateX(-50%);"  color="black" size="300" >
                <v-img src="../assets/avatar.jpg"></v-img>
              </v-avatar>
            </div>
          </v-col>

          <v-col cols="12" sm="6" style="text-align: left; padding-left: 60px;">
            <h4 class="mt-16 text-black titles" style="display: block;">About Me</h4>
            <div class="zone-line">
              <v-slider v-model="slider2" max="50" min="0" thumb-size="10" color="#111">
              </v-slider>
            </div>
            <table class="text-black intro">
              <tr>
                <td>
                  A software engineering new graduate in 
                  Ottawa with reliable ability in software development and programming.
                  Passion for solving practical problems with reliable and efficient software.
                </td>
              </tr>
              <br>
              <tr>
                <td> Interested in front-end technologies too, like fancy and eye-catching UI design. 
                  Willing to translate amazing design into responsive and scaleable web page. </td>
              </tr>
              <br>
              <tr>
                <td>
                    Comfortable working with various programming languages and related frameworks.
                    Can efficiently and quickly learn the use of open-source tools. 
                    Currently seeking enter-level opportunities to start career.

                </td>
              </tr>
            </table>
          </v-col>

        </v-row>
      </div>
    </v-col>
 <v-row id="bio">
    <v-col cols="2"></v-col>
    <v-col>
      <span class="mt-16 text-black titles">Bio</span>
      <div class="zone-line">
        <v-slider v-model="slider2" max="50" min="0" thumb-size="10" color="#111">
        </v-slider>
      </div>
      <table class="text-black intro" >
        <tr>
          <td >1997</td> &nbsp;&nbsp; <td>Born in Beijing, China.</td>
        </tr>
        <tr>
          <td>2015</td> &nbsp;&nbsp; <td>BA in Journalism and Communication(Advertising) at Beijing Technology And Business University.</td>
        </tr>
        <tr>
          <td>2016</td>&nbsp;&nbsp;<td>Came to Canada.</td>
        </tr>
        <tr>
          <td>2017</td>&nbsp;&nbsp;<td>B.Eng in Software Engineering at Carleton University.</td>
        </tr>
        <tr>
          <td>2021</td>&nbsp;&nbsp;<td>M.Eng in Software Engineering at Western University.</td>
        </tr>
      </table>
        <br>
      <span class="mt-16 text-black titles">Tchnical Stack</span>
      <div class="zone-line">
        <v-slider v-model="slider2" max="50" min="0" thumb-size="10" color="#111">
        </v-slider>
      </div>
      <table class="text-black intro tchStack" >
        <th>•Programming Language</th>
        <tr>
          <td >
            <v-icon > fa-brands fa-java</v-icon>
            <v-icon > fa-brands fa-square-js</v-icon>
            <v-icon > fa-brands fa-html5</v-icon>
            <v-icon > fa-brands fa-css3</v-icon>
            <v-icon > fa-brands fa-python</v-icon>
            <v-icon > fa-brands fa-linux</v-icon>
          </td>
        </tr>
        <th>•Libraries/API</th>
        <tr>
          <td>
            <v-icon > fa-brands fa-vuejs</v-icon>
            <v-icon > fa-brands fa-react</v-icon>
          </td>
        </tr>
        <th>•Framework</th>
        <tr>
          <td><v-icon >fa-brands fa-node</v-icon></td>
        </tr>
      </table>
      <a href="https://drive.google.com/uc?export=download&id=1QYQXD1KN_y7tdMoKvdHgbezI0Fn4cAbm" style="text-decoration: none;">
        <v-btn class="mt-4 text-white" color="#111">Donload Resume</v-btn>
      </a>
    </v-col>
  <v-col cols="2"></v-col>
 </v-row>


<v-divider></v-divider>

<v-col cols="12" sm="12" id="project" >
  <v-row>
    <v-col style="width:10px;">
      <div class="d-flex justify-center mb-6"  style="width:200px; margin: auto;">
        <v-btn color="#111" class="mr-2 text-white" @click="toAll()">All</v-btn>
        <v-btn class="mr-2" variant="tonal" @click="toProject(1)">Tic</v-btn>
        <v-btn class="mr-2" variant="tonal" @click="toProject(2)">Sketch Pad</v-btn>
        <v-btn class="mr-2" variant="tonal">project#3</v-btn>
        <v-btn variant="tonal"> project#5</v-btn>
      </div>
    </v-col>
  </v-row>
  <div  class="project-page" v-if="all">
    <v-row>
      <v-col>
          <v-card width="300 px" @click="toProject(1)">
            <v-img src="../assets/TicTacToe-clone-demo.png"  cover> </v-img>
            <v-card-text>A clone of google's TicTacToe game</v-card-text>
          </v-card>
      </v-col>

      <v-col>
        <v-card width="300 px" @click="toProject(2)">
          <v-img src="../assets/SketchPad.png"  cover> </v-img>
          <v-card-text>A Java Swing sketch pad appliction</v-card-text>
        </v-card>
      </v-col>

      <v-col>
          <v-card width="300 px">
            <v-img src="../assets/jumping.gif"  cover> </v-img>
          </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
          <v-card width="300 px">
            <v-img src="../assets/jumping.gif"  cover> </v-img>
          </v-card>
      </v-col>
      <v-col>
        <v-card width="300 px">
          <v-img src="../assets/jumping.gif"  cover> </v-img>
        </v-card>
      </v-col>

      <v-col>
          <v-card width="300 px">
            <v-img src="../assets/jumping.gif"  cover> </v-img>
          </v-card>
      </v-col>
    </v-row>
  </div>

  <div class="project-page" v-if="!all&&(this.project==1) ">
    <v-row>
      <v-col cols="2"></v-col>
      <v-col>
        <p>A clone of Google's tic-tac-toe game
          <a href="https://www.google.com/fbx?fbx=tic_tac_toe">(original)</a> that allows players to 
          choose AIs of varying difficulty and play against friends. 
          Cloned most of the UI and interaction effects.
          In order to restore the original UI design and interactive 
          animation effects of the Google version, 
          static and dynamic SVGs are used. Component-based programming using Vue.js
          and used the concept of state management. 
          Implemented the miniMax algorithm for AI on "impossible" level,
           making the AI at this level invincible
        </p><br>
        <div style="align-items: center;">
          <a href="/tic-tac-toe" style="text-decoration: none; color: black; display: block;">
            <v-btn variant="tonal" class="abusolute" style="margin-left: 50%;transform:translateX(-50%) ;">Demo</v-btn> 
          </a>
        </div>
        <br>
        <v-card> <v-img src="../assets/TicTacToe-clone-demo.png"  cover></v-img></v-card>
       
    
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    
    
  </div>





  <div  class="project-page" v-if="!all&&(this.project==2) ">
    <v-row>
      <v-col cols="2"></v-col>
      <v-col>
        <p>A small application based on Java swing, 
          users can choose different colors and shapes to draw on the sketch pad. 
          Operations on the drawn graphics components were implemented, 
          such as moving, deleting, and grouping. Basic functions such as redo undo, 
          saving and reading are also implemented.
        </p><br>
        <p>Used a simplifed vesion of MVC model for stucture design.
          Because the user-triggered events in this application are less and simple,
          the sketchPad Class combined the funciton of model and controller.

        </p>
        <br>
        <v-card> <v-card-text>The UML of Design Stucture</v-card-text> <v-img src="../assets/strcture.png"  cover></v-img></v-card>
        <v-card> <v-card-text>Draw</v-card-text> <v-img src="../assets/draw.gif"  cover></v-img></v-card>
        <v-card> <v-card-text>Move</v-card-text> <v-img src="../assets/move.gif"  cover></v-img></v-card>
        <v-card> <v-card-text>Delete</v-card-text> <v-img src="../assets/delete.gif"  cover></v-img></v-card>
        <v-card> <v-card-text>Undo & Redo</v-card-text> <v-img src="../assets//undo-redo.gif"  cover></v-img></v-card>
        <v-card> <v-card-text>grouping</v-card-text><v-img src="../assets//group.gif"  cover></v-img></v-card>
       
    
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    
  </div>






</v-col>







        
      <FootBar></FootBar>
    </v-container>
  </v-app>
</template>


<script>
import NavBar from '@/components/NavBar.vue';
import FootBar from '@/components/FootBar.vue';
import { defineComponent } from 'vue';

// Components

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      all:true,
      project:0,
    }
  },
  setup(){
    return {slider2:0,}
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    NavBar,
    FootBar
  },
  methods:{
    scroll(refName){
    const element = document.getElementById(refName)
    element.scrollIntoView({behavior:"smooth"});
    },
    handleScroll () {
      var yPos1 = -(window.scrollY * 1 / 100);
      var yPos2 = -(window.scrollY * 50 / 100);

      var div1 = document.getElementById("foreground");
      var div2 = document.getElementById("background");

      div1.setAttribute('style', 'transform: translate3d(0px, ' + yPos1 + 'px, 0px)');
      div2.setAttribute('style', 'transform: translate3d(0px, ' + yPos2 + 'px, 0px)');
    },

    toProject(num){
      this.all=false
      this.project=num
    },
    toAll(){
      this.all=true
      this.project=0
    }
  },
});

</script>

<style>
  .v-container{
    padding: 16px 0;
  }
  #bigLogo{
    stroke-width: 4px;
  stroke-dasharray: 570;
  stroke-dashoffset: 570;
  transform: translateX(-50px) translateY(0px);
  animation: line-grow 2s forwards;
  }

  @keyframes line-grow{

    to{
      stroke-dashoffset: 0;
    }
  }


  .head{
    position: absolute;
    text-align: center;
    padding:12px;
    margin-bottom: 6px;
    height:300px;
    width: 100%;
    z-index: 1;
    color: white;
  }
  .head::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: #111 ;
    transform:  skew(0deg, 9deg);
  }

  .head::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background-color: #111 ;
    transform:  skew(0deg, -9deg);
  }
  .head2{
    position:absolute;
    margin-top: 0px;
    z-index: -1;
    animation: head2-move-down 1s forwards ease-in-out;
  }
  @keyframes head2-move-down{
    to{
      margin-top: 100px;
    }
  }
  .head2::before{
    background-color: rgb(75, 75, 75) ;
  }
  .head2::after{
    background-color: rgb(75, 75, 75) ;
  }
  
  .greeting div{
    transform: translateY(-30px);
    margin-top: 0;
  }
#about{
  padding-top: 400px;
  opacity: 0;
  transform: translateY(-100px);
  animation: about-out 1s forwards linear 1s;
}
#bio{
  opacity: 0;
  transform: translateY(-100px);
  animation: about-out 1s forwards linear 1s;
}
@keyframes about-out{
  to{
    opacity: 1;
    transform: translateY(0);
  }
}
.avater-background{
  margin-left: 50%;
  margin-top:0;

  transform: translateX(-50%);
  display: block;
  background-color: rgb(1, 1, 1);
  width: 400px;
  height: 350px;
  border-radius: 50% 50% 50% 50% /60% 60% 40% 40%;
}
.titles{
  font-weight: 600;
  font-size: large;
}
.v-card:hover{
 background-color: rgba(75, 75, 75, 0.354);
}

.v-slider{
  height: 25px;
}
.aboutMe td{
  text-align: justify;
  
}
.zone-line{
  width: 20%px;
}
.tchStack th{
  padding-top: 3px;
  text-align: left;

}
.v-icon{
  padding:0px 30px 0px 0px;
}
.v-divider{
  margin: 25px 50px;
}
.v-card{
  width: 300;
  text-align: center;
}

.project-page{
  opacity: 0;
  animation: p-page-out 2s linear forwards;
}
@keyframes p-page-out{
  to{
    opacity: 1;
  }
}
</style>
