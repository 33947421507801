<template>
    <v-footer>
        <v-card
          elevation="0"
          rounded="0"
          width="100%"
          class="text-center"
          style="background-color: #111;"
        >
          <v-card-text class="text-white">
            <a href="https://www.linkedin.com/in/zewen-chen-0544881b7/">
                <v-btn
                class="mx-4"
                variant="plain"
                color="white"
                > <v-icon>fa-brands fa-linkedin</v-icon> <strong>Linkedin</strong> </v-btn>
            </a>
           
          </v-card-text>
  
          <v-card-text class="text-white">
            @ {{ new Date().getFullYear() }}
          </v-card-text>
        </v-card>
      </v-footer>
  </template>
  
  <script>
  export default {
  }
  </script>
  
  <style>
  .v-footer {
    padding: 8px 0 !important;
  }
  a{
    text-decoration: none;
    text-align: bottom;
  }
  .v-icon{
    transform: translateX(3px) translateY(-3px);
  }
  </style>