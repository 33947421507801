<template>
  <v-app-bar app color="#111" dark flat class="px-16">
    <svg class="logo" display="relative" width="40" height="40" viewBox="0 0 168 174" fill="none">
          <path d="M25.5 21L14.5 1H167L90.5 133L80 115L134 21H25.5Z" stroke="white" />
          <path d="M142.736 153.3L153.731 173.303L1.23145 173.268L77.7617 41.2851L88.2576 59.2875L34.236 153.275L142.736 153.3Z" stroke="white"/>
      </svg>
 
        <v-btn class="text-white" @click="scroll('home')">
          ZewenChen
        </v-btn>

 

    <v-spacer></v-spacer>
    <v-btn test @click ="scroll('home')" class="text-white">Home</v-btn>
    <v-btn test @click ="scroll('about')" class="text-white">About</v-btn>
    <v-btn test @click ="scroll('project')" class="text-white">Projects</v-btn>
  </v-app-bar>
</template>

<script>
    export default {
        methods:{
          scroll(refName){{
            const element = document.getElementById(refName)
            element.scrollIntoView({behavior:"smooth"});
          }},
        },
    };
</script>

<style scoped>
 .logo{
  stroke-width: 4px;
  stroke-dasharray: 570;
  stroke-dashoffset: 570;
  animation: line-grow 2s forwards;
 }
 .logo:hover{
  stroke-dashoffset: 570;
  animation: line-grow 1s forwards;
 }
 @keyframes line-grow{
  to{
    stroke-dashoffset: 0;
  }
 }
</style>